import { Button, Stack, Tooltip } from "@mui/material";
import { blueGrey } from "@mui/material/colors";
import React from "react";
import Util from "../services/util";
import { IFormEvent } from "./Form.interface";
import BodyTool, { BodyToolAc } from "./FormBodyTool";
import FormImage, { types } from "./FormImage";
import FormText from "./FormText";
import axios from "axios";
import ImgsetService from "../services/imgset.service";
import SparkMD5 from "spark-md5";
import { ContentPasteGo } from "@mui/icons-material";

export interface BodyItem {
  type: "p" | "t" | "i" | "a" | "v" | "md" | 'w' | 'b';
  value: string;
}

interface Form {
  body: BodyItem[];
}

export default function FormBody(props: {
  mb?: number,
  value: BodyItem[];
  onChange: (body: Form['body']) => void
}) {
  
  const [form, setForm] = React.useState<Form>({ body: props.value || [] })
  const handleChange = (e: IFormEvent) => {
    const newValue: Form = Util.formChange(e, form)
    // console.log(newValue);
    setForm(newValue)
    // props.onChange(newValue.body)
  }
  React.useEffect(() => {
    props.onChange(form.body);
  }, [form]);

  const handleBodyToolClick = (index: number, ac: BodyToolAc) => {
    if (ac === 'del') {
      // console.log(index, ac);
      if (index === -1) {
        form.body.splice(0, 1)
        setForm({ body: [...form.body] })
      } else {
        setForm({ body: [...form.body.slice(0, index), ...form.body.slice(index + 1)] })
      }
    } else {
      if (index === -1) {
        setForm({ body: [...form.body, { type: ac, value: '' }] })
      } else {
        setForm({ body: [...form.body.slice(0, index + 1), { type: ac, value: '' }, ...form.body.slice(index + 1)] })
      }
    }
  }

  function handlePaste(e: any, index: number) {
    navigator.clipboard.read().then((r) => {
      const _index = index
      for (const clipboardItem of r) {
        for (const type of clipboardItem.types) {
          if (type.split('/')[0] === 'image') {
            clipboardItem.getType(type).then((file) => {
              new ImgsetService().readimg(file).subscribe((base64) => {
                if (!base64) return;
                const extName = file.type.split("/")[1];
                const fileType = file.type.split("/")[0];
                if (
                  !(extName && fileType && types[fileType]?.includes(`.${extName}`))
                ) {
                  return;
                }
                const filename = `${fileType}/${Date.now()}${SparkMD5.hash(
                  base64
                )}.${extName}`;
                axios
                  .post("upload", { filename, file: base64, count: 1, index: 1 })
                  .then(() => {
                    setForm({
                      ...form,
                      body: [
                        ...form.body.slice(0, _index),
                        { type: "i", value: filename },
                        ...form.body.slice(_index),
                      ],
                    });
                  });
              })
            })
          };
        }
      }
    })
    navigator.clipboard.readText().then(text => {
      if (index < 0) { index = 0 }
      console.log(index);
      const type = ['p', 't'].includes(form.body[index]?.type) ? form.body[index].type : 'p'
      if (text) {
        form.body[index] = text
          .split("\n")
          .map((i: string) => ({ type, value: i })) as any;
        setForm({ ...form, body: form.body.flat(1) });
      }
    })
    return
  }


  const itemTool = (index: number) => (
    <Stack direction={"row"} flex={{ alignItems: 'flex-end', backgroundColor: blueGrey[500] + '20', borderRadius: 5 }}>
      <Tooltip title="粘贴" >
        <Button onClick={(e) => handlePaste(e, index)} startIcon={<ContentPasteGo />} />
      </Tooltip>
      <Stack flexGrow={1}></Stack>
      <BodyTool
        actions={["p", "t", "i", "b", "a", "v", "w", "md", "del"]}
        onClick={(t) => handleBodyToolClick(index, t)}></BodyTool>
    </Stack>
  )

  return <Stack spacing={2}>
    {itemTool(-1)}
    {form?.body?.map((i, index) => {
      switch (i.type) {
        case 'i':
          return <Stack key={index}>
            <FormImage
              name={`body.${index}.value`} value={form.body[index].value}
              label={`图片`} onChange={handleChange} />
            {itemTool(index)}
          </Stack>
        case 'a':
          return <Stack key={index}>
            <FormImage
              name={`body.${index}.value`} value={form.body[index].value}
              label={`音频`} onChange={handleChange} />
            {itemTool(index)}
          </Stack>
        case 'v':
          return <Stack key={index}>
            <FormImage
              name={`body.${index}.value`} value={form.body[index].value}
              label={`视频`} onChange={handleChange} />
            {itemTool(index)}
          </Stack>
        case 't':
          return <Stack key={index}>
            <FormText name={`body.${index}.value`} value={form.body[index].value}
              label={`居中文本`} onChange={handleChange} inputProps={{ sx: { textAlign: 'center' } }} />
            {itemTool(index)}
          </Stack>
        case 'w':
          return <Stack key={index}>
            <FormText name={`body.${index}.value`} value={form.body[index].value}
              label={`外部地址`} onChange={handleChange} />
            {itemTool(index)}
          </Stack>
        case 'b':
          return <Stack key={index}>
            <FormText name={`body.${index}.value`} value={form.body[index].value}
              label={`加粗文本`} onChange={handleChange} inputProps={{ sx: { textAlign: 'center' } }} />
            {itemTool(index)}
          </Stack>
        case 'md':
          return <Stack key={index}>
            <FormText name={`body.${index}.value`} value={form.body[index].value}
              label={`Markdown`} onChange={handleChange} multiline rows={4} />
            {itemTool(index)}
          </Stack>
        default:
          return <Stack key={index}>
            <FormText name={`body.${index}.value`} value={form.body[index].value}
              label={`段落文本`} onChange={handleChange} multiline rows={2} />
            {itemTool(index)}
          </Stack>
      }
    })}
    <Stack sx={{ height: props.mb === undefined ? 200 : props.mb }}></Stack>
  </Stack>
}
