import { blue, blueGrey, cyan } from "@mui/material/colors";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import React, { lazy, Suspense, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomeIndex from "./home/home-index";
import License from "./home/License";
import { apiInit } from "./services/api.service";
import { colorMode } from "./services/app.service";
import Drawer from "./ui/drawer";
import Loading from "./ui/loading";
import NotFound from "./ui/notfound";
import Snker from "./ui/Snker";
import Jump from "./weigets/Jump";
import MyToolbar from "./weigets/MyToolbar";
import "moment/locale/zh-cn";
import moment from "moment";
import CmsRouter from "./cms/CmsRouter";
const VoteRouter = lazy(() => import("./vote/VoteRouter"));
const SystemRouter = lazy(() => import("./system/SystemRouter"));
const ExamRouter = lazy(() => import("./exam/ExamRouter"));
const PrizeRouter = lazy(() => import("./prize/PrizeRouter"));
const SurveyRouter = lazy(() => import("./survey/SurveyRouter"));
const FeedbackRouter = lazy(() => import("./feedback/FeedbackRouter"));
const PlantRouter = lazy(() => import("./plant/PlantRouter"));
moment().locale("zh-cn");
apiInit();

export default function App() {
  const [isDark, setIsDark] = React.useState<boolean>(colorMode.isDark);
  useEffect(() => {
    const _sub = colorMode.sub.subscribe((v) => {
      colorMode.isDark = v;
      setIsDark(v);
    });
    return () => _sub.unsubscribe();
  });

  const theme = React.useMemo(() => {
    const t = createTheme({
      typography: {
        fontFamily:
          "-apple-system, BlinkMacSystemFont, 'Segoe UI' ,'Roboto', 'Helvetica', 'Arial', sans-serif",
      },
      palette: {
        mode: isDark ? "dark" : "light",
        primary: { main: isDark ? blue[300] : blue[900] },
        secondary: { main: isDark ? cyan[500] : cyan[800] },
      },
    });
    const html = document.querySelector("html");
    if (html) {
      html.style.backgroundColor = isDark ? "#000" : blueGrey.A100;
      html.style.minHeight = "100vh";
    }
    return t;
  }, [isDark]);

  const El = (el: JSX.Element) => (
    <Suspense fallback={<Loading />}>{el}</Suspense>
  );
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <CssBaseline />
        <MyToolbar />
        <Snker />
        <Drawer />
        <Routes>
          <Route path="" element={<HomeIndex />} />
          <Route path="license" element={<License />} />
          <Route path="vote/*" element={El(<VoteRouter />)}></Route>
          <Route path="exam/*" element={El(<ExamRouter />)}></Route>
          <Route path="survey/*" element={El(<SurveyRouter />)}></Route>
          <Route path="prize/*" element={El(<PrizeRouter />)}></Route>
          <Route path="feedback/*" element={El(<FeedbackRouter />)}></Route>
          <Route path="plant/*" element={El(<PlantRouter />)}></Route>
          <Route path="cms/*" element={El(<CmsRouter />)}></Route>
          <Route path="system/*" element={El(<SystemRouter />)}></Route>
          <Route path="survey/*" element={<SurveyRouter />} />
          <Route path="jump/*" element={<Jump />} />
          <Route path="jump-news/*" element={<Jump />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}
