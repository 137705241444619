import { IFormEvent } from "../form/Form.interface";
import { ILicense } from "../home/License.interface";
import { APIHOST } from "./api.service";
import nzh from "nzh/cn";
import { snk } from "./app.service";
import { utils, writeFile } from "xlsx";

const Util = {
	randomString, // 随机字符串
	randomNum, // 随机数字
	checkMobileNum, //验证手机号是否正确
	maskMobile, // 手机号掩码 189****8888
	pushToArray, // 数组中不存在则加入 或 乒乓方法
	intersection, // 求交集 
	toArray, // 对象转数组
	toObject, // 数组转对象
	toJSONStr, //对象转JSON字符串
	formChange, // 通过修改事件重新产生表单数据
	mediaId, // 从 URI 得到媒体ID
	mediaSrc, // 产生媒体安全下载地址
	sum, // 数组求和
	downCsv, // 下载文本为文件
	toMoney, // 货币格式显示
	ymd, // 以数组方式返回年月日
	toMoneyCn, // 大写数字
	preZero, // 将数字转换为 补0 后的字符串
	readTextFile, // 读取本地文本文件
	readXlsxFile, // 读取本地 Excel 文件
	toXlsxFile, // 导出为 exce 文件
	tobase64, // File 转 base64
	moveElement, //数组元素移动位置
};
export default Util;

// 求和
function sum(arr: any[]) {
	if (arr.length < 2) return Number(arr[0]);
	return Number(arr.reduce((a, b) => (Number(a) || 0) + (Number(b) || 0), 0));
}

function randomString(len = 32) {
	let rdmStr = "";
	for (
		rdmStr;
		rdmStr.length < len;
		rdmStr += Math.random().toString(36).substring(2)
	) { }
	return rdmStr.substring(0, len);
}

function randomNum(minNum: number, maxNum: number) {
	switch (arguments.length) {
		case 1:
			return Math.random() * minNum + 1;
		case 2:
			return Math.random() * (maxNum - minNum + 1) + minNum;
		default:
			return 0;
	}
}

function mediaId(url: string) {
	const m = (url || "").match(/\?id=([\w]{24})/i);
	return m ? m[1] || url : url;
}

function mediaSrc(url: string, license: ILicense) {
	return `${APIHOST}userfile?id=${Util.mediaId(url)}&appid=${license.appId
		}&name=${license.name}&sn=${license.sn}`;
}

function checkMobileNum(str: string) {
	return /^1\d{10}$/.test(str);
}

function maskMobile(str = "") {
	return `${str.substring(0, 3)}****${str.substring(7)}`;
}

function pushToArray(arr: any[], value: any, toggle = true) {
	const index = arr.indexOf(value);
	if (index > -1) {
		if (toggle) {
			arr.splice(index, 1);
		}
	} else {
		arr.push(value);
	}
}

function intersection(a: any[], b: string | any[]) {
	return a.filter((i) => b.indexOf(i) > -1);
}

function toArray(obj: any = {}, keyName = "_id") {
	const value = Object.entries<any>(obj);
	return value.map(([k, v]) => ({ ...v, [keyName]: k }));
}

function toObject(arr: any[] = [], keyName = "_id", only?: string) {
	const obj = {};
	arr.forEach((i) => {
		Object.assign(obj, { [i[keyName]]: only ? i[only] : i });
	});
	return obj;
}

function toJSONStr(json_data: any, space = 0) {
	if (typeof json_data === "string") {
		return json_data;
	}
	var cache: any[] | null = [];
	var json_str = JSON.stringify(
		json_data,
		function (key, value) {
			if (typeof value === "object" && value !== null) {
				if (cache?.indexOf(value) !== -1) {
					return;
				}
				cache.push(value);
			}
			return value;
		},
		space
	);
	cache = null;
	return json_str;
}

function formChange(e: IFormEvent, form: any) {
	const { name, value, type } = e.target;
	if (name.includes(".")) {
		const names: string[] = name.split(".");
		const nf = Array.isArray(form) ? [...form] : { ...form } as any;

		let cur: any = nf;
		names.forEach((i, index) => {
			if (index === names.length - 1) {
				cur[i] = type === "number" && value ? Number(value) : value;
			} else {
				cur = cur[i];
			}
		});
		return nf;
	} else {
		return { ...form, [name]: value };
	}
}

// 下载为文件
function downCsv(filename: string, text: string) {
	var element = document.createElement("a");
	element.setAttribute(
		"href",
		"data:text/plain;charset=utf-8," + encodeURIComponent(text)
	);
	element.setAttribute("download", filename);
	element.style.display = "none";
	document.body.appendChild(element);
	element.click();
	document.body.removeChild(element);
}

function toMoney(n: number, nozero = true, pre = "￥") {
	if (!n) return nozero ? "" : pre + "0.00";
	const str = Math.abs(n).toString().split(".")[0].split("").reverse();
	if (str.length === 1) {
		str.push("0");
	}
	const r = [...str.splice(0, 2), "."];
	if (!str.length) {
		r.push("0");
	}
	while (str.length) {
		const a = str.splice(0, 3);
		r.push(...a, str.length ? "," : "");
	}
	if (n < 0) {
		r.push("-");
	}
	r.push(pre);
	return r.reverse().join("");
}

// 年月日
function ymd(data?: Date | number | string) {
	const t = data ? new Date(data) : new Date();
	return [t.getFullYear(), t.getMonth(), t.getDate()];
}

// 大写数字
function toMoneyCn(n: number) {
	return nzh.toMoney(n, { outSymbol: false });
}
// 补0
function preZero(number: number | string, len: number = 3) {
	const n = `${number}`;
	return len - n.length > 0
		? Array(len - n.length)
			.fill("0")
			.join("") + n
		: n;
}

// 读取文本文件
async function readTextFile(extNames?: string[]): Promise<string> {
	if (!showOpenFilePicker) return "";
	const opt = extNames ? { accept: { "application/*": extNames } } : {};
	const [r] = await showOpenFilePicker({ ...opt, multiple: false });
	const file = await r.getFile();
	const reader = new FileReader();
	if (!file) return "";
	return new Promise((resolve) => {
		try {
			reader.onload = () => {
				resolve(reader.result as string);
			};
			reader.readAsText(file);
		} catch (e) {
			resolve("");
		}
	});
}


async function readXlsxFile(extNames?: string[]): Promise<ArrayBuffer | null> {
	return new Promise(async (resolve) => {
		if (!showOpenFilePicker) {
			snk.next({ msg: "浏览不支持", severity: "warning" });
		}
		try {
			const opt = extNames ? { accept: { "application/*": extNames } } : {};
			const r = await showOpenFilePicker({ ...opt, multiple: false });
			const file = await r[0]?.getFile();
			const reader = new FileReader();
			reader.onload = () => {
				resolve(reader.result as ArrayBuffer);
			};
			reader.onerror = () => {
				resolve(null);
			};
			reader.readAsArrayBuffer(file);
		} catch (e) {
			resolve(null);
		}
	});
}

// 导出为 excel
function toXlsxFile(jsonData: any[], fileName: string, sheetName = "sheet1") {
	const workBook = utils.book_new();
	const ws1 = utils.json_to_sheet(jsonData);
	utils.book_append_sheet(workBook, ws1, sheetName);
	writeFile(workBook, fileName);
}

function tobase64(file: File): Promise<string> {
	const reader = new FileReader();
	return new Promise((resolve) => {
		if (!file) resolve("");
		try {
			reader.onload = () => {
				resolve(reader.result as string);
			};
			reader.readAsDataURL(file);
		} catch (e) {
			resolve("");
		}
	});

}
function moveElement(arr: any[], fromIndex: number, toIndex: number) {
	const elemToMove = arr.splice(fromIndex, 1)[0];
	arr.splice(toIndex, 0, elemToMove);
	return arr;
}