import { Stack } from "@mui/material";

export default function Logo(props: {
  isBlue?: boolean;
  height?: number;
  onlyIcon?: boolean;
}) {


  return (
    <Stack sx={{ height: 58, mr: 2, display: "flex" }}>
      <img style={{ width: "3.4rem", height: "3.4rem" }} src={`/png/64x64.png`} alt="" />
    </Stack>
  );
}
