import DeleteIcon from "@mui/icons-material/Delete";
import Label from "@mui/icons-material/Label";
import SendIcon from '@mui/icons-material/Send';
import { Box, Button, Container, Divider, Stack, Tab, Table, TableBody, TableCell, TableRow, Tabs, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import FormText from "../form/FormText";
import { Appinfo } from "../services/app.service";
import MoreMenu from "../ui/MoreMenu";
import { ILicense } from "./License.interface";

window.postMessage({ type: 'ss' })


const getLocalStorage = (name: string) => {
  try {
    const l = localStorage.getItem(name)
    return l ? JSON.parse(l) : null
  } catch (err) {
    return null
  }
}
const getLicenseOn = () => {
  const v = getLocalStorage('licenseOn') || 0
  return Number(v)
}
const getLicense = () => {
  const v: ILicense[] = getLocalStorage('license') || []
  return v
}

export const useLicense = () => getLicense()[getLicenseOn()] || {}


export default function License() {
  const [licenses, setLicenses] = React.useState<ILicense[]>(getLicense())
  const [licenseOn, setLicenseOn] = React.useState<number>(getLicenseOn())
  const [form, setForm] = React.useState<ILicense>({ appId: '', name: '', sn: '', mark: '' })
  const [isDirty, setIsDirty] = React.useState(false)
  const [tab, setTab] = React.useState('')
  const navigate = useNavigate()

  const handleChange = (e: any) => {
    const { target: { value, name } } = e
    setForm({ ...form, [name]: value })
    setIsDirty(true)
  }

  const save = () => {
    const nv = [...licenses]
    nv.unshift({ ...form })
    setLicenses(nv)
    localStorage.setItem('license', JSON.stringify(nv))
    setForm({ appId: '', name: '', sn: '', mark: '' })
    setIsDirty(false)
  }

  const active = (index: number) => {
    setLicenseOn(index)
    localStorage.setItem('licenseOn', index.toString())
  }

  const remove = (index: number) => {
    const nv = [...licenses]
    nv.splice(index, 1)
    setLicenses(nv)
    if (index === licenseOn) {
      localStorage.setItem('licenseOn', "-1")
      setLicenseOn(-1)
    }
    localStorage.setItem('license', JSON.stringify(nv))
  }

  return (
    <Container sx={{ pt: 10, minHeight: '100vh' }}>
      <Stack direction="row" spacing={1}><Label /><Box>已授权({licenses.length}):</Box></Stack>
      <Tabs value={tab} onChange={(el, v) => setTab(v)}>
        {[['', '全部'], ...Object.entries(Appinfo.appName)].map((i, index) => (<Tab key={index} value={i[0]} label={<Stack>
          <Typography variant="caption" fontSize={.5}>{i[0]}</Typography>
          <Typography variant="body2" >{i[1]}</Typography>
        </Stack>} />))}
      </Tabs>
      <Divider />
      <Stack spacing={2} sx={{ mt: 2 }}>
        <Table>
          <TableBody>
            {licenses.map((i, index) => {
              return !tab || i.appId === tab
                ? (<TableRow key={index} hover>
                  <TableCell><em>{i.appId}</em></TableCell>
                  <TableCell>{i.mark || Appinfo.appName[i.appId] || i.appId}</TableCell>
                  <TableCell>{i.name}</TableCell>
                  <TableCell sx={{ p: 0 }}>
                    <Button variant={index === licenseOn ? 'contained' : 'outlined'} onClick={() => {
                      if (index !== licenseOn) {
                        active(index);
                      }
                      navigate('/' + i.appId);
                    }}
                      startIcon={<SendIcon />}
                    >访问</Button>
                    <MoreMenu menuItem={[{ icon: <DeleteIcon />, title: "删除", onClick: () => remove(index) }]} />
                  </TableCell>
                </TableRow>)
                : null
            })}
          </TableBody>
        </Table>

        <Stack direction="row" spacing={2}>
          <FormText label="AppId" value={form.appId} name="appId" onPaste={(e) => {
            try {
              const text = e.clipboardData.getData('text')
              const mc = text.split('\n').map(i => i.split(':')[1])
              setTimeout(() => setForm({ appId: mc[0], name: mc[1], sn: mc[2].trim(), mark: mc[3] }), 10)
              e.stopPropagation()
              return false
            } catch (e) { }
          }} onChange={handleChange} />
          <FormText label="Name" value={form.name} name="name" onChange={handleChange} />
          <FormText label="SN" value={form.sn} name="sn" onChange={handleChange} />
          <FormText label="备注" value={form.mark} name="mark" onChange={handleChange} />
          <Button disabled={!isDirty} variant="contained" onClick={save}>添加</Button>
        </Stack>
      </Stack >
      <Stack sx={{ p: 2 }} spacing={2}>
        <Typography variant="body2">
          使用此应用需要 <a href={Appinfo.webSite} target="_blank" rel="noreferrer">{ Appinfo.owner}</a> 授权，
          任何非授权的行为，包括且不限于使用、复制、传播、二次开发等，将受到本公司法律诉讼或其他权利保护行为的约束。
        </Typography>
        <Typography variant="body2">
          如需使用必须获得{ Appinfo.owner}授予的序列号
        </Typography>
      </Stack>
    </Container>
  )
}
