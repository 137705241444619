import MoreVert from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import React from 'react';
interface MmenuItem {
	icon?: JSX.Element | undefined;
	title: string;
	onClick?: () => void;
}
export default function MoreMenu(props: { menuItem: MmenuItem[] }) {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const { menuItem } = props;
	return (
		<>
			<IconButton onClick={e => setAnchorEl(e.currentTarget)}>
				<MoreVert />
			</IconButton>
			<Menu anchorEl={anchorEl} open={!!anchorEl} onClose={() => setAnchorEl(null)}>
				{menuItem.map((i, index) => (
					<MenuItem
						key={index}
						onClick={() => {
							setAnchorEl(null);
							i.onClick && i.onClick();
						}}
					>
						{i.icon}
						<ListItemText sx={{ pl: !i.icon ? 4 : 1 }}>{i.title}</ListItemText>
					</MenuItem>
				))}
			</Menu>
		</>
	);
}
