// import DateAdapter from "@mui/lab/AdapterMoment";
// import DateTimePicker from "@mui/lab/DateTimePicker";
// import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import TextField from "@mui/material/TextField";
import { DateTimePicker, DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import moment from "moment";
import "moment/locale/zh-cn";
import * as React from "react";
import { FormProps } from "./Form.interface";
moment().locale("zh-cn");

export default function FormDate(props: FormProps) {
  const { label, name, onChange, inputFormat, mask, time, minDate, maxDate, sx } = props;
  const [value, setValue] = React.useState(
    moment(props.value).toDate() || moment().toDate()
  );
  React.useEffect(() => {
    setValue(moment(props.value).toDate() || moment().toDate())
  }, [props.value])
  const handleChange = (newValue: any) => {
    const d: moment.Moment = newValue;
    if (d) {
      setValue(d.toDate());
      onChange({ target: { name, value: d.toDate().valueOf() } });
    }
  };
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      {time
        ? <DateTimePicker
          minDate={minDate}
          maxDate={maxDate}
          renderInput={(props) => <TextField sx={sx}  {...props} />}
          inputFormat={inputFormat || "yyyy/MM/DD HH:mm"}
          label={label}
          value={value}
          mask={mask || "____/__/__ __:__"}
          views={['year', 'month', 'day']}
          onChange={handleChange}
        />
        : <DesktopDatePicker renderInput={(props) => <TextField sx={sx} {...props} />}
          minDate={minDate}
          maxDate={maxDate}
          views={['year', 'month', 'day']}
          inputFormat={inputFormat || "yyyy/MM/DD"}
          label={label}
          value={value}
          mask={mask || "____/__/__"}
          onChange={handleChange} />}
    </LocalizationProvider>
  );
}
