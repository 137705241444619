import Stack from "@mui/material/Stack";
import styled from "@mui/material/styles/styled";
import axios from "axios";
import { marked } from "marked";
import React from "react";
import Loading from "./loading";

const StyledMd = styled(Stack)(({ theme }) => ({
  "& p": { ...theme.typography.body1, textIndent: "2rem", lineHeight: 1.6 },
  "& h1": { ...theme.typography.h1, fontSize: 40 },
  "& h2": { ...theme.typography.h2, fontSize: 30 },
  "& h3": { ...theme.typography.h3, fontSize: 20 },
  "& h4": { ...theme.typography.h4, fontSize: 16 },
  "& h5": { ...theme.typography.h5, fontSize: 14 },
  "& h6": { ...theme.typography.h6, fontSize: 12 },
  "& a": {
    color: theme.palette.text.secondary,
    marginLeft: 1,
    marginRight: 16,
  },
  "& a:hover": { color: theme.palette.secondary[theme.palette.mode] },
  "& pre": {
    fontFamily:
      "source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace",
    fontSize: 14,
    lineHeight: 1.2,
    color: "rgba(245,245,245,1)",
    padding: 12,
    borderRadius: 5,
    backgroundColor: "rgba(55,71,79,1)",
    overflow: "auto",
  },
}));

export default function Markdown(props: { src?: string; srcData?: string }) {
  const { src, srcData } = props;
  const [md, setMd] = React.useState<string>();

  React.useEffect(() => {
    if (src) {
      axios.get(src).then((r) => {
        const html = marked.parse(r?.data || "");
        setMd(html);
      });
    } else {
      const html = marked.parse(srcData || "无内容");
      setMd(html);
    }
  }, [src, srcData]);

  return (
    (md && (
      <StyledMd
        dangerouslySetInnerHTML={{ __html: md }}
        ref={(ref: HTMLDivElement) => {
          if (ref) {
            const a = ref.querySelectorAll("a");
            a.forEach((i) => {
              i.setAttribute("target", "_blank");
            });
            // for (let i in a) {
            // 	console.log(i);

            // }
          }
        }}
      />
    )) || <Loading variant="table" />
  );
}
