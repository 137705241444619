import { List, ListItem, ListItemIcon, ListItemText, Stack } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLicense } from '../home/License';
import { drawer } from '../services/app.service';
import SystemSide from '../system/SystemSide';
import { SettingsIcon } from '../vote/Icons';
import VoteSide from '../vote/VoteSide';

export default function Drawer() {
  const [open, setOpen] = useState(drawer.open);
  const license = useLicense()
  const navigate = useNavigate()
  useEffect(() => {
    const _sub = drawer.sub.subscribe(v => setOpen(v));
    return () => _sub.unsubscribe();
  }, []);


  const menu = () => {
    const c: { [x: string]: any } = {
      vote: <VoteSide />,
      system: <SystemSide />,
    }
    return c[license.appId] || <List>
      <Stack flex={{ flexGrow: 1 }} />
      <ListItem button color="text.disabled" onClick={() => {
        navigate('/license')
      }}>
        <ListItemIcon sx={{ minWidth: 32 }}><SettingsIcon />  </ListItemIcon>
        <ListItemText>许可</ListItemText>
      </ListItem>
    </List>
  }
  return (
    <MuiDrawer
      open={open}
      sx={{ width: 240, mt: 10 }}
      onClose={() => {
        drawer.sub.next(false);
      }}
    >
      <Stack sx={{ maxWidth: '80vw', width: 240, height: '100%' }}>
        {menu()}
      </Stack>
    </MuiDrawer>
  );
}
