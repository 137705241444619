import { Add, DoDisturbAlt, Done } from "@mui/icons-material";
import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import axios from "axios";
import moment from "moment";
import React from "react";
import { IFormEvent } from "../form/Form.interface";
import FormBody from "../form/FormBody";
import FormDate from "../form/FormDate";
import FormKeyword from "../form/FormKeyword";
import FormSelect from "../form/FormSelect";
import FormSwitch from "../form/FormSwitch";
import FormText from "../form/FormText";
import Util from "../services/util";
import DataCommon from "../ui/DataCommon";
import DataPage from "../ui/DataPage";
import DisplayBody from "../ui/DisplayBody";
import { Icategory, Ipage } from "./cms.interface";

export default function CmsPage() {
  const [category, setCategory] = React.useState<Icategory[]>();
  React.useEffect(() => {
    axios.post("cms/category").then((r) => setCategory(r.data));
  }, []);

  const [query, setQuery] = React.useState({
    keyword: "",
    limit: 10,
    skip: 0,
    category: "",
  });
  const [data, setData] = React.useState<{ count: number; data: Ipage[] }>();
  const [form, setForm] = React.useState<Ipage>();
  const [preview, setPreview] = React.useState(false);

  function find() {
    const { keyword, limit, skip, category } = query;
    const k = keyword ? { title: { $regex: query.keyword } } : {};
    axios
      .post("cms/findPage", {
        where: { ...k, paths: category || undefined },
        limit,
        skip,
      })
      .then((r) => {
        setData(r.data);
      });
  }
  React.useEffect(find, [query]);

  function handleQueryChange(e: IFormEvent) {
    setQuery(Util.formChange(e, { ...query, skip: 0 }));
  }

  function handleFormChange(e: IFormEvent) {
    setForm(Util.formChange(e, form));
  }

  function budCate(
    pid = "",
    p: string = ""
  ): { title: string; value: string }[] {
    if (!category) return [];
    const a = [
      ...category
        .filter((i) => i.pid === pid)
        .map((i) => {
          const title = p ? `${p} / ${i.title}` : i.title;
          return [{ title, value: i._id! }, ...budCate(i._id, title)];
        }),
    ];
    return a.flat();
  }

  function getPaths(_id: string): string[] {
    const c = category?.find((i) => i._id === _id);
    if (c?.pid) {
      return [...getPaths(c.pid), _id];
    } else {
      return [_id];
    }
  }

  function getPathsName(paths: string[]) {
    return category?.find((x) => x._id === paths.at(-1))?.title;
  }

  function handleSave() {
    if (!form?.category) return;
    const { title, time, body, _id, status } = form;
    const paths = getPaths(form.category);
    axios.post("cms/savePage", { title, time, body, paths, _id, status: status ? 1 : 0 }).then((r) => {
      setForm(undefined);
      setQuery({ ...query });
    });
  }

  function handleRemove() { }

  return (
    <Container>
      <Stack spacing={2}>
        <Stack direction={"row"} spacing={2} sx={{ mt: 2 }} alignItems="center">
          <FormSelect
            label="类别"
            name="category"
            value={query.category}
            onChange={handleQueryChange}
            options={[{ title: "全部", value: "" }, ...budCate()]}
          />
          <FormKeyword
            label="搜索"
            name="keyword"
            value={query.keyword}
            onChange={handleQueryChange}
          />
          <Stack flexGrow={1}></Stack>
          <Button
            variant="contained"
            startIcon={<Add />}
            onClick={() => {
              setForm({ category: query.category, time: Date.now() } as Ipage);
              setPreview(false);
            }}
          >
            添加
          </Button>
        </Stack>
        {data && data.data.length > 0 && (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>时间</TableCell>
                <TableCell>栏目</TableCell>
                <TableCell>标题</TableCell>
                <TableCell>审核</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.data.map((i, index) => {
                return (
                  <TableRow
                    key={index}
                    onClick={() => {
                      setForm({
                        ...i,
                        category: i.paths.at(-1),
                      });
                      setPreview(false);
                    }}
                  >
                    <TableCell>
                      {moment(i.time).format("YYYY-MM-DD HH:mm")}
                    </TableCell>
                    <TableCell>{getPathsName(i.paths)}</TableCell>
                    <TableCell>{i.title || "无标题"}</TableCell>
                    <TableCell sx={{ p: 0 }}>{i.status ? <Done /> : <DoDisturbAlt />}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        )}
        <DataPage count={data?.count} query={query} setQuery={setQuery} />
        <DataCommon data={data?.data} />
      </Stack>

      {form && (
        <Dialog open={true} onClose={() => setForm(undefined)} maxWidth="md">
          <DialogTitle sx={{ minWidth: "50vw" }}>
            <Stack flexGrow={1}>{form._id ? "修改" : "添加"}</Stack>
          </DialogTitle>
          <DialogContent>
            {!preview ? (
              <Stack sx={{ pt: 2 }} spacing={2}>
                <FormSelect
                  label="栏目"
                  name="category"
                  value={form.category}
                  options={budCate()}
                  onChange={handleFormChange}
                />
                <Stack direction={"row"}>
                  <FormDate
                    time={true}
                    label="时间"
                    name="time"
                    value={form.time}
                    onChange={handleFormChange}
                  />
                  <Stack flexGrow={1}></Stack>
                  <FormSwitch name="status" label="审核" value={form.status} onChange={handleFormChange} />
                </Stack>
                <FormText
                  label="标题"
                  name="title"
                  value={form.title}
                  onChange={handleFormChange}
                />
                <FormBody
                  value={form.body}
                  onChange={(body) => {
                    setForm({ ...form, body });
                  }}
                />
              </Stack>
            ) : (
              <Stack>
                <Typography variant="h5" textAlign={"center"}>
                  {form.title}
                </Typography>
                <Typography variant="caption" textAlign={"center"}>
                  {getPathsName([form.category || ""]) || "栏目未选择"}{" "}
                  {moment(form.time).format("y-MM-DD")}
                </Typography>
                <Divider sx={{ mb: 4 }}></Divider>
                <DisplayBody body={form.body || []}></DisplayBody>
              </Stack>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleRemove}>删除</Button>
            <Stack flexGrow={1}></Stack>
            <Button onClick={() => setForm(undefined)}>取消</Button>
            <Button
              variant={preview ? "contained" : "outlined"}
              color={"secondary"}
              onClick={() => setPreview(!preview)}
            >
              预览
            </Button>
            <Button
              variant="contained"
              disabled={!form?.category}
              onClick={handleSave}
            >
              保存
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Container>
  );
}
