import { Alert, Button, Pagination } from "@mui/material"
import Loading from "./loading"


export default function DataCommon(props: {
  data?: any;
  nodataLabel?: string;
  reloadFun?: any;
  error?: string,
  query?: any
  setQuery?: any
  sx?: any
}) {
  const { data, nodataLabel, reloadFun, error, query, setQuery, sx } = props

  return !data
    ? <Loading variant="table" />

    : error
      ? <Alert
        sx={{ m: 2 }}
        color="error"
        action={<Button color="inherit" size="small" onClick={reloadFun}>重试</Button>}
      >{error}</Alert>

      : data && ((data.length || data.data?.length || 0) === 0)
        ? <Alert
          sx={{ m: 2 }}
          color="info"
          action={<Button color="inherit" size="small" onClick={reloadFun}>刷新</Button>}
        >{nodataLabel || "没有数据"}</Alert>

        : query
          ? <Pagination
            sx={sx}
            page={query.skip / query.limit + 1}
            count={Math.ceil(data.count / query.limit)}
            onChange={(e, page) => {
              setQuery && setQuery({
                ...query,
                skip: (page - 1) * query.limit,
              });
            }}
          />
          : null

}
