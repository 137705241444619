import { Box } from "@mui/material";
import React from "react";
import { Outlet } from "react-router-dom";
import CmsSide from "./CmsSide";

export default function CmsMain() {
  return (
    <React.Fragment>
      <Box
        sx={{
          width: 240,
          display: { xs: "none", md: "block" },
          position: "fixed",
          paddingTop: "56px",
          height: "100vh",
          borderRight: "1px solid rgba(150,150,150,.2)",
        }}
      >
        <CmsSide />
      </Box>
      <Box
        sx={{
          ml: { xs: 0, md: "240px" },
          pt: "60px",
          minHeight: "100vh",
        }}
      >
        <Outlet></Outlet>
      </Box>
    </React.Fragment>
  );
}
