import { Box, Button, Container, Stack, Typography } from '@mui/material';
import anime from 'animejs';
import { useNavigate } from 'react-router-dom';
import { Appinfo } from '../services/app.service';
import { useLicense } from './License';

/**
 * 首页
 * @returns
 */
export default function HomeIndex() {
	const navigate = useNavigate();
	const license = useLicense()

	//======================================================动画

	const logoAnime = () => {
		anime({
			targets: '#svg-container .st2',
			strokeDashoffset: [anime.setDashoffset],
			easing: 'easeInOutSine',
			duration: 1000,
			delay: function (el, i) {
				return i * 200;
			},
			direction: 'alternate'
		});
	};

	function nameenAnime() {
		anime({
			targets: '.ani-nameengrid .el',
			opacity: 1,
			scale: [0, 1],
			delay: anime.stagger(100, { grid: [14, 5], from: 'first' })
		});
		setTimeout(() => {
			nameAnime();
		}, 300);
	}

	function nameAnime() {
		anime({
			targets: '.ani-namegrid .el',
			opacity: [0, 1],
			translateX: [-100, 0],
			scale: [{ value: [0, 1], easing: 'easeInOutQuad', duration: 500 }],
			delay: anime.stagger(50, { grid: [14, 5] }),
			update: ani => {
				if (ani.progress === 100) {
					labelAnime();
				}
			}
		});
	}


	function labelAnime() {
		anime({
			targets: '.ani-label',
			opacity: [0.3, 1.0],
			translateX: [100, 0],
			duration: 1200
		});
		anime({
			targets: '.ani-serviceCard',
			opacity: [0.3, 0.9],
			translateY: [200, 0],
			delay: anime.stagger(100, { grid: [14, 5] }),
			update: ani => {
			}
		});
	}
	//======================================================

	// React.useEffect(() => {
	// 	axios
	// 		.get('logo_dark.svg')
	// 		.then((res: { data: string }) => {
	// 			let r = res.data;
	// 			if (theme.palette.mode === 'light') {
	// 				r = r.replaceAll('#66A5DA', pink[300]);
	// 			}
	// 			const a = document.getElementById('svg-container');
	// 			if (a) {
	// 				a.innerHTML = r;
	// 				logoAnime();
	// 				nameenAnime();
	// 			}
	// 		})
	// 		.catch(() => { });
	// });
	//======================================================Elment
	return (
		<Container
			sx={{
				pt: 10,
				pb: 10
				// bgcolor: (theme.palette.mode === 'light' && grey[300] + '4a') || null
			}}
			onClick={() => {
				// window.location.href = 'https://yourdata.plus'
			}}
		>
			<Stack alignItems="center">
				<img width="512" height="512" src="/png/512x512.png" alt="" />
			</Stack>
			<Stack sx={{ mt: 6, textAlign: 'center' }} spacing={2}>
				{license?.appId && <Button variant="contained" color="success" size="large" onClick={(e) => {
					navigate(license?.appId || 'license');
					e.stopPropagation()
				}}>
					<Typography sx={{ mr: 1 }}>进入 </Typography>
					<Typography sx={{ mr: 1 }}>{
						Appinfo.appName[license.appId] || license.appId
					}</Typography>
					{/* {license.name} */}
				</Button>}
				<Button variant={license?.appId ? 'text' : "contained"} color="success" size="large" onClick={(e) => {
					navigate('license');
					e.stopPropagation()
				}}>
					许可设置
				</Button>
			</Stack>
			<Box color="text.disabled" sx={{ letterSpacing: '2px', mt: 6, textAlign: 'center' }}>
				每甘云 版权所有
			</Box>
		</Container>
	);
}
