import { AwesomeQRCode } from "@awesomeqr/react";
import { Box, Container, Stack } from "@mui/material";
import { useLicense } from "../home/License";
import { snk } from "../services/app.service";

export default function CmsQr() {
  const license = useLicense();
  return (
    <Container>
      <Stack sx={{ minHeight: "90vh" }}>
        <Stack
          sx={{
            width: 400,
            height: 400,
            m: "auto",
            textAlign: "center",
            cursor: "pointer",
          }}
          spacing={2}
          onClick={(e) => {
            try {
              const el = e.currentTarget.firstElementChild!
                .firstElementChild as HTMLDivElement;
              const src = el.style.backgroundImage.substring(
                5,
                el.style.backgroundImage.length - 2
              );
              const a = document.createElement("a");
              var event = new MouseEvent("click");
              a.href = src;
              a.download = license.mark + "二维码";
              a.dispatchEvent(event);
            } catch (e) {
              snk.next({ msg: "下载失败, 请截图", severity: "warning" });
            }
          }}
        >
          <AwesomeQRCode
            options={{
              text: `https://api.yourdata.plus/jump/cms?topicId=${license.name}`,
              size: 400,
              autoColor: true,
              components: {
                data: { scale: 0.8 },
              },
            }}
            onStateChange={(state) => {
              console.log(state);
            }}
          />
          <Box>点击下载</Box>
        </Stack>
        <Box>
          {/* {license.mark} 预览:{" "} */}
          {/* <a href={`/h5/cms/${license.name}`} target="_blank">
            http://api.yourdata.plus/h5/cms/{license.name}
          </a> */}
        </Box>
      </Stack>
    </Container>
  );
}
