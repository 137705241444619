import { Button, Container, Stack, Typography } from "@mui/material";
import axios from "axios";
import React from "react";
import { IFormEvent } from "../form/Form.interface";
import FormBody from "../form/FormBody";
import FormImage from "../form/FormImage";
import { snk } from "../services/app.service";
import Util from "../services/util";
import Loading from "../ui/loading";

export default function CmsTopic() {
  const [data, setData] = React.useState<any>();
  function find() {
    axios.post("cms/findTopic").then((r) => {
      setData(r.data);
    });
  }
  React.useEffect(find, []);

  function handleChange(e: IFormEvent) {
    setData({ ...Util.formChange(e, data), isDirty: true });
  }

  function handleSave() {
    const { headAd, profile, bgm, logo } = data;
    axios.post("cms/saveTopic", { headAd, profile, bgm, logo }).then((r) => {
      snk.next({ msg: "已保存" });
    });
  }
  return data ? (
    <Container>
      <Stack sx={{ pt: 2 }} spacing={2}>
        <Stack direction={"row"}>
          <Stack flexGrow={1}></Stack>
          <Button
            disabled={!data.isDirty}
            variant="contained"
            onClick={handleSave}
          >
            保存
          </Button>
        </Stack>
        <Typography variant="h6" textAlign={"center"}>
          {data.topic}
        </Typography>

        {/* <FormText label="主题名" name="topic" value={data.topic} onChange={handleChange} /> */}
        <Stack >
          <FormImage
            sx={{ width: 144, height: 144 }}
            name={`logo`}
            value={data.logo}
            label="Logo"
            onChange={handleChange}
          />
        </Stack>
        <Typography variant="caption">广告图</Typography>
        {data.headAd.map((i: any, index: number) => (
          <Stack key={index} direction="row">
            <Stack flex={{ flexGrow: 1 }}>
              <FormImage
                name={`headAd.${index}`}
                value={data.headAd[index]}
                label={`头部图片${index + 1}`}
                onChange={handleChange}
              />
            </Stack>
            <Stack flex={{ justifyContent: "center" }}>
              <Button
                onClick={() => {
                  const headAd = [...data.headAd];
                  headAd.splice(index, 1);
                  setData({ ...data, headAd, isDirty: true });
                }}
              >
                删除
              </Button>
            </Stack>
          </Stack>
        ))}
        <Button
          onClick={() => {
            setData({ ...data, headAd: [...data.headAd, ""], isDirty: true });
          }}
        >
          添加
        </Button>

        <Typography component={"div"} sx={{ m: 2, ml: 0 }} variant="caption">背景音乐</Typography>
        {data.bgm?.map((i: any, index: number) => (
          <Stack key={index} direction="row">
            <Stack flex={{ flexGrow: 1 }}>
              <FormImage
                name={`bgm.${index}`}
                value={data.bgm[index]}
                label={`音频${index + 1}`}
                onChange={handleChange}
              />
            </Stack>
            <Stack flex={{ justifyContent: "center" }}>
              <Button
                onClick={() => {
                  const bgm = [...data.bgm];
                  bgm.splice(index, 1);
                  setData({ ...data, bgm });
                }}
              >
                删除
              </Button>
            </Stack>
          </Stack>
        ))}
        <Button
          onClick={() => {
            setData({ ...data, bgm: [...data.bgm, ""], isDirty: true });
          }}
        >
          添加
        </Button>

        <Typography variant="caption">介绍页</Typography>

        <FormBody
          value={data.profile}
          onChange={(profile) => setData({ ...data, profile, isDirty: true })}
        />
      </Stack>

      {/* {JSON.stringify(data)} */}
    </Container>
  ) : (
    <Loading variant="table" />
  );
}
