import { FormatBold, InsertLink } from '@mui/icons-material';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import AudioFileOutlinedIcon from '@mui/icons-material/AudioFileOutlined';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import LineWeightOutlinedIcon from '@mui/icons-material/LineWeightOutlined';
import MaximizeIcon from '@mui/icons-material/Maximize';
import PhotoCameraFrontIcon from '@mui/icons-material/PhotoCameraFront';
import TextFormatIcon from '@mui/icons-material/TextFormat';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Tooltip from '@mui/material/Tooltip';
import { BodyItem } from './FormBody';

export type BodyToolAc = BodyItem['type'] | 'del'

export default function BodyTool(props: {
  onClick: (type: BodyToolAc) => void;
  actions?: BodyToolAc[]
}) {
  const { onClick, actions } = props
  const sx = { sx: { '& .MuiButton-startIcon': { m: 0 } } };
  const bts: {
    name: BodyToolAc,
    title: string
    icon: JSX.Element
  }[] = [
      { name: 'p', title: "文本", icon: <TextFormatIcon /> },
      { name: 't', title: "标题(居中文本)", icon: <MaximizeIcon /> },
      { name: 'b', title: "大标题", icon: <FormatBold /> },
      { name: 'md', title: "Markdown", icon: <LineWeightOutlinedIcon /> },
      { name: 'i', title: "图片", icon: <AddPhotoAlternateIcon /> },
      { name: 'a', title: "音频", icon: <AudioFileOutlinedIcon /> },
      { name: 'v', title: "视频", icon: <PhotoCameraFrontIcon /> },
      { name: 'w', title: "外部", icon: <InsertLink /> },
      { name: 'del', title: "删除本条", icon: <DeleteForeverIcon /> }
    ]

  return (
    <ButtonGroup variant="text" color="inherit">
      {bts.filter(i => !actions || actions.includes(i.name)).map((i, index) => (
        <Tooltip {...sx} title={i.title} key={index}>
          <Button onClick={() => onClick(i.name)} startIcon={i.icon} />
        </Tooltip>
      ))}
    </ButtonGroup>
  );
}
