import { Route, Routes } from "react-router-dom";
import NotFound from "../ui/notfound";
import CmsCategory from "./CmsCategory";
import CmsMain from "./CmsMain";
import CmsPage from "./CmsPage";
import CmsQr from "./CmsQr";
import CmsTopic from "./CmsTopic";

export default function CmsRouter() {
  return (
    <Routes>
      <Route path="/" element={<CmsMain />}>
        <Route path="topic" element={<CmsTopic />} />
        <Route path="category" element={<CmsCategory />} />
        <Route path="page" element={<CmsPage />} />
        <Route path="qr" element={<CmsQr />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
}
