import { AwesomeQRCode } from "@awesomeqr/react";
import { Container, Stack } from "@mui/material";
import React from "react";


export default function Jump() {
  const [url] = React.useState(window.location.href)



  return <Container >
    <Stack sx={{ height: "20vh", justifyContent: 'flex-end', alignItems: 'center' }}>
      请用微信扫一扫
    </Stack>
    <Stack sx={{ height: "70vh" }} flex={{ justifyContent: 'center', alignItems: 'center' }}>
      <AwesomeQRCode
        options={{
          text: url,
          size: 400,
          autoColor: true,
          components: {
            data: { scale: .8 }
          }
        }}
        onStateChange={() => {
        }}
      />
    </Stack>
  </Container>
}