import { Description, HowToVote, Settings, Storage } from "@mui/icons-material";
import QrCode from "@mui/icons-material/QrCode";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { drawer } from "../services/app.service";

export default function VoteSide() {
  const params = useParams();
  const navigate = useNavigate();
  const pathname = params["*"] || "voteRecord";

  const list = [
    { icon: <HowToVote />, title: "主题设置", name: "/cms/topic" },
    { icon: <Storage />, title: "栏目", name: "/cms/category" },
    { icon: <Description />, title: "内容", name: "/cms/page" },
    { icon: <QrCode />, title: "二维码", name: "/cms/qr" },
  ];
  return (
    <List
      sx={{ height: "100%", display: "flex", flexDirection: "column" }}
    >
      {list.map((i, index) => (
        <ListItem disablePadding key={index}>
          <ListItemButton
            selected={pathname === i.name}
            onClick={() => {
              drawer.sub.next(false);
              navigate(i.name);
            }}
          >
            <ListItemIcon sx={{ minWidth: 32 }}>{i.icon}</ListItemIcon>
            <ListItemText>{i.title}</ListItemText>
          </ListItemButton>
        </ListItem>
      ))}
      <Stack flex={{ flexGrow: 1 }} />
      <ListItem>
        <ListItemButton
          color="text.disabled"
          onClick={() => {
            navigate("/license");
          }}
        >
          <ListItemIcon sx={{ minWidth: 32 }}>
            <Settings />{" "}
          </ListItemIcon>
          <ListItemText>许可</ListItemText>
        </ListItemButton>
      </ListItem>
    </List>
  );
}
