import { Subject } from 'rxjs';

export const Appinfo: { owner: string, webSite: string, appName: { [appid: string]: string } } = {
  owner: '每甘云',
  webSite: 'https://www.gansudaily.com.cn',
  appName: {
    vote: '投票管理',
    exam: '答卷管理',
    survey:'问卷管理',
    cms: "内容管理",
    plant:'3.12植树节',
    prize: '领奖助手',
    feedback: '反馈中心',
    marketing: '营销助手',
    system: '云管理'
  }
}

//==============================={ 抽屉 }
export const drawer = {
  open: false,
  sub: new Subject<boolean>()
};
//==============================={ 颜色模式 }
export const colorMode = {
  isDark: window.matchMedia('(prefers-color-scheme: dark)').matches,
  sub: new Subject<boolean>()
};

//==============================={ 提示 }
export interface Snk {
  open?: boolean;
  msg: string;
  severity?: 'error' | 'info' | 'success' | 'warning';
  autoHideDuration?: number;
}
export const snk = new Subject<Snk>();

//==============================={ 缓存 }
export const cache: {} = {

};
