import ClearIcon from "@mui/icons-material/Clear";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import { FormProps } from "./Form.interface";
/**
 *
 * @param props: {value, onSubmit, onChange}
 */

export default function FormKeyword(props: FormProps) {
  return (
    <TextField
      label={props.label || "搜"}
      variant="filled"
      {...props.props}
      value={props.value}
      onKeyDown={(e) => (e.key === "Enter" ? props.onChange : undefined)}
      name={props.name || "keyword"}
      onChange={props.onChange}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              onClick={() =>
                props.onChange({
                  target: { name: props.name || "keyword", value: "" },
                })
              }
            >
              <ClearIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    ></TextField>
  );
}
