import DarkModeIcon from "@mui/icons-material/DarkMode";
import LightModeIcon from "@mui/icons-material/LightMode";
import MenuIcon from "@mui/icons-material/Menu";
import { AppBar, Box, IconButton, Toolbar, useTheme } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useLicense } from "../home/License";
import { Appinfo, colorMode, drawer } from "../services/app.service";
import Logo from "../ui/logo";

export default function MyToolbar() {
  const theme = useTheme();
  const navigate = useNavigate();
  const license = useLicense();

  return (
    <AppBar position="fixed">
      <Toolbar>
        <IconButton
          sx={{
            mr: 2,
            display: { xs: "block", md: "none" },
          }}
          color="inherit"
          onClick={() => {
            drawer.sub.next(true);
          }}
        >
          <MenuIcon></MenuIcon>
        </IconButton>

        <Box onClick={() => navigate("/")} sx={{ cursor: "pointer" }}><Logo /></Box>
        <Box sx={{ display: { xs: "none", sm: "block" }, fontWeight: 'bold', fontSize: 20 }}>
          {Appinfo.appName[license.appId] || ""}
        </Box>
        <Box flex={{ flexGrow: 1 }}></Box>
        <IconButton sx={{ ml: 1 }} onClick={() => colorMode.sub.next(!colorMode.isDark)} color="inherit">
          {theme.palette.mode === "dark" ? <LightModeIcon /> : <DarkModeIcon />}
        </IconButton>
      </Toolbar>
    </AppBar>
  );
}
