import { Pagination } from "@mui/material";
import React from "react";

export default function DataPage(props: {
  query: any;
  count?: number;
  setQuery: any;
}) {
  const { query, count, setQuery } = props;
  return count && count > 0 ? (
    <Pagination
      showFirstButton
      showLastButton
      page={query.skip / query.limit + 1}
      count={Math.ceil(count / query.limit)}
      onChange={(e, page) => {
        setQuery({ ...query, skip: (page - 1) * query.limit });
      }}
    />
  ) : null;
}
