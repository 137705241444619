import { Analytics, AutoMode, Insights, PhonelinkLock, RecentActors, Speed, Storage } from "@mui/icons-material";

import QrCodeIconIcon from "@mui/icons-material/QrCode";
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { HowToVoteIcon, SettingsIcon } from "./Icons";

export default function VoteSide() {
  const params = useParams()
  const navigate = useNavigate();
  const pathname = params['*'] || 'voteChart'

  const list = [
    { icon: <Speed />, title: "Dashboard", name: "voteChart" },
    { icon: <HowToVoteIcon />, title: "主题设置", name: "voteTopic" },
    { icon: <Storage />, title: "投票项", name: "voteItem" },
    { icon: <RecentActors />, title: "投票记录", name: "voteRecord" },
    { icon: <Insights />, title: "排行榜", name: "voteRank" },
    { icon: <AutoMode />, title: "数据处理", name: "voteAuto" },
    { icon: <Analytics />, title: "数据分析", name: "voteData" },
    { icon: <PhonelinkLock />, title: "黑名单", name: "voteBlack" },
    { icon: <QrCodeIconIcon />, title: "二维码", name: "voteQr" },
  ]
  return (
    <List sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      {list.map((i, index) => (
        <ListItem disablePadding key={index} onClick={() => {
          navigate(i.name)
        }}>
          <ListItemButton selected={pathname === i.name} >
            <ListItemIcon sx={{ minWidth: 32 }}>{i.icon}</ListItemIcon>
            <ListItemText primary={i.title} />
          </ListItemButton>
        </ListItem> 
      ))}
      <Stack flex={{ flexGrow: 1 }} />
      <ListItem color="text.disabled" onClick={() => {
        navigate('/license')
      }}>
        <ListItemIcon sx={{ minWidth: 32 }}><SettingsIcon />  </ListItemIcon>
        <ListItemText>许可</ListItemText>
      </ListItem>
    </List>
  )
}