import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { Box, Stack } from "@mui/material";
import axios from 'axios';
import React from 'react';
import SparkMD5 from 'spark-md5';
import { useLicense } from '../home/License';
import { APIHOST } from '../services/api.service';
import { snk } from '../services/app.service';
import ImgsetService from '../services/imgset.service';
import Util from '../services/util';
import { FormProps } from './Form.interface';

export const types: { [key: string]: string[] } = {
  image: ['.png', '.gif', '.jpeg', '.jpg', '.jpeg', '.svg', '.webp'],
  video: ['.mp4', '.mpeg', '.ogg', '.webm'],
  audio: ['.3gp', '.webm', '.mp3', '.acc']
};

export default function FormImage(props: FormProps) {
  const { label, name, onChange, sx } = props
  const [value, setValue] = React.useState<string>(props.value)
  const [valueExt, setValueExt] = React.useState<string>('image')
  React.useEffect(() => {
    if (value) {
      const ext = '.' + value.split('.').at(-1)
      const curType = Object.entries(types).filter(i => i[1].includes(ext))[0]
      if (curType) {
        setValueExt(curType[0])
      }
    }
  }, [value])

  const license = useLicense()
  const uploadFile = (filename: string, file: string, count = 1, index = 1) => {
    return axios.post('upload', { filename, file, count, index });
  };

  const loadFile = (file: File) => {
    if (!file) return;
    const chunkSize = 1024 * 1024;
    const imgset = new ImgsetService();
    const extName = file.name.split('.').at(-1)
    const fileType = file.type.split('/')[0]
    if (!(extName && fileType && types[fileType]?.includes(`.${extName}`))) {
      snk.next({ msg: '文件类型不支持', severity: "warning" })
      return
    }


    if (file.size > chunkSize) {
      let c = 0;
      const filename = `${fileType}/${Date.now()}${Util.randomString(8)}.${file.name.split('.').at(-1)}`;
      const next = (count: number) => {
        snk.next({
          open: true,
          severity: 'info',
          msg:
            count * 2 === c
              ? '上传完成 100%'
              : '正在上传 ' + Math.round((c / 2 / count) * 100).toString() + '%'
        });
      };
      imgset.readBig(file).subscribe(([base64, count, index]) => {
        c++;
        next(count);
        uploadFile(filename, base64, count, index).then(() => {
          c++;
          if (count * 2 === c) {
            setValue(filename)
            onChange({ target: { name, value: filename } })
          }
          next(count);
        });
      });

    } else {
      imgset.readimg(file).subscribe(base64 => {
        const filename = `${fileType}/${Date.now()}${SparkMD5.hash(base64)}.${file.name.split('.').at(-1)}`;
        uploadFile(filename, base64).then(() => {
          setValue(filename)
          onChange({ target: { name, value: filename } })
        });
      });
    }

  };

  return <Box sx={{
    display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: "hidden",
    minHeight: 100, minWidth: 100,
    backgroundColor: "rgba(100, 100, 100, .3)",
    ':hover': name ? { backgroundColor: "rgba(100, 100, 100, .4)", cursor: "pointer" } : {},
    ...sx
  }}
    onClick={() => {
      if (!name || !(window as any).showOpenFilePicker) return;
      (window as any).showOpenFilePicker({
        accept: { 'image/*': ['.png', '.gif', '.jpeg', '.jpg', '.jpeg', '.svg', '.webp'] },
        multiple: false
      })
        .then((r: { getFile: (arg0: number) => Promise<File>; }[]) => {
          r[0].getFile(0).then((f: File) => {
            loadFile(f);
          });
        })
    }}
  >
    {value ?
      (valueExt === 'video' ?
        <video src={APIHOST + 'upload/' + license.appId + '/' + license.name + '/' + value} />
        :
        (valueExt === 'audio' ?
          <audio controls={true} src={APIHOST + 'upload/' + license.appId + '/' + license.name + '/' + value} />
          :
          <img src={APIHOST + 'upload/' + license.appId + '/' + license.name + '/' + value} alt={label} />))
      :
      <Stack flex={{ alignItems: 'center' }}><AddPhotoAlternateIcon />{label} </Stack>}
  </Box>
}