import { AdUnits, CardGiftcard, ContentCopy, DriveFileMove, Feedback, HomeWork, HowToVote, ManageAccounts, NaturePeople, PictureInPicture, QuestionAnswer, Settings, SettingsSuggest, SpeakerNotes, Webhook } from "@mui/icons-material";
import { List, ListItem, ListItemIcon, ListItemText, Stack } from "@mui/material";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

export default function SystemSide() {
  const location = useLocation();
  const navigate = useNavigate();
  const pathname =
    location.pathname.split("/").slice(2, 3).join("") || "snset";

  const list = [
    { icon: <ManageAccounts />, title: "主题授权", name: "snset" },
    { icon: <DriveFileMove />, title: "文件管理", name: "resources" },
    { icon: <SettingsSuggest />, title: "配置文档", name: "configure" },
    { icon: <HomeWork />, title: "小程序首页", name: "wapphome" },
    { icon: <HowToVote />, title: "投票", name: "voteTopic" },
    { icon: <QuestionAnswer />, title: "答卷", name: "examTopic" },
    { icon: <SpeakerNotes />, title: "问卷", name: "surveyTopic" },
    { icon: <CardGiftcard />, title: "领奖助手", name: "prizeTopic" },
    { icon: <Feedback />, title: "反馈中心", name: "feedbackTopic" },
    { icon: <ContentCopy />, title: "企业画册", name: "cms" },
    { icon: <NaturePeople />, title: "3.12植树节", name: "plantTopic" },
    { icon: <PictureInPicture />, title: "平台广告", name: "systemAds" },
    // { icon: <SettingsInputHdmi />, title: "Post Father", name: "postfather" },
  ];
  return (
    <List sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
      {list.map((i, index) => (
        <ListItem key={index} button selected={pathname.includes(i.name)} onClick={() => { navigate(i.name); }}>
          <ListItemIcon sx={{ minWidth: 32 }}>{i.icon}</ListItemIcon>
          <ListItemText>{i.title}</ListItemText>
        </ListItem>
      ))}
      <Stack flex={{ flexGrow: 1 }} />
      <ListItem button color="text.disabled" onClick={() => {navigate("/license"); }}>
        <ListItemIcon sx={{ minWidth: 32 }}>
          <Settings />{" "}
        </ListItemIcon>
        <ListItemText>许可</ListItemText>
      </ListItem>
    </List>
  );
}
