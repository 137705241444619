import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import React from 'react';
import { Snk, snk } from '../services/app.service';

const defaultValue: Snk = {
	open: false,
	msg: '',
	severity: 'info',
	autoHideDuration: 6000
};

export default function Snker() {
	const [state, setState] = React.useState(defaultValue);
	const theme = useTheme();
	const md = useMediaQuery(theme.breakpoints.up('md'));

	function handleClose() {
		setState({ ...state, open: false });
	}

	React.useEffect(() => {
		const _sub = snk.subscribe(value => {
			setState({ ...defaultValue, open: true, ...value });
		});
		return () => _sub.unsubscribe();
	}, []);

	return (
		<Snackbar
			open={state.open}
			autoHideDuration={state.autoHideDuration}
			anchorOrigin={{ horizontal: 'center', vertical: md ? 'top' : 'bottom' }}
			onClose={handleClose}
		>
			<Alert onClose={handleClose} severity={state.severity} sx={{ width: '100%' }}>
				{state.msg}
			</Alert>
		</Snackbar>
	);
}
