import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import axios from "axios";
import React from "react";
import { IFormEvent } from "../form/Form.interface";
import FormImage from "../form/FormImage";
import FormText from "../form/FormText";
import { snk } from "../services/app.service";
import Util from "../services/util";
import Loading from "../ui/loading";
import { Icategory } from "./cms.interface";

export default function CmsCategory() {
  const [data, setData] = React.useState<Icategory[]>();
  const [form, setForm] = React.useState<Icategory>();
  function find() {
    axios.post("cms/category").then((r) => {
      setData(r.data);
    });
  }
  React.useEffect(find, []);

  function handleAdd(pid: string, sort: number = 0) {
    if (!data) return;
    axios.post("cms/saveCategory", { title: "", pid, sort }).then((r) => {
      const { _id, pid, sort, title } = r.data as Icategory;
      if (_id) {
        const n = { _id, pid, sort, title } as Icategory;
        setData([...data, n].sort((a, b) => (a.sort > b.sort ? 1 : -1)));
        setForm({ ...n });
      } else {
        snk.next({ msg: "失败", severity: "error" });
      }
    });
  }

  function handleFormChange(e: IFormEvent) {
    setForm(Util.formChange(e, form));
  }

  function handleSave() {
    const { title, sort, _id, bgi } = form!;
    axios.post("cms/saveCategory", { title, sort, _id, bgi }).then(() => {
      setForm(undefined);
      find();
    });
  }

  function handleRemove() {
    axios.post("cms/removeCategory", { _id: form!._id }).then(() => {
      setForm(undefined);
      find();
    });
  }

  return data ? (
    <Container>
      <Stack direction={"row"}>
        <Stack flexGrow={1}></Stack>
      </Stack>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>标题</TableCell>
            <TableCell>排序</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody sx={{ td: { p: 0 } }}>{row()}</TableBody>
      </Table>
      {form && (
        <Dialog open={true} onClose={() => setForm(undefined)}>
          <DialogTitle>{form.title || "新栏目"}</DialogTitle>
          <DialogContent>
            <Stack sx={{ pt: 2 }} spacing={2}>
              <FormText
                label="标题"
                name="title"
                value={form.title}
                onChange={handleFormChange}
              />
              <FormText
                type={"namber"}
                label="排序"
                name="sort"
                value={form.sort}
                onChange={handleFormChange}
              />
            </Stack>
            {form.pid === '' && <React.Fragment>
              <Typography component={"div"} sx={{ m: 2, ml: 0 }} variant="caption">背景图片</Typography>
              {form.bgi?.map((i: any, index: number) => (
                <Stack key={index} direction="row">
                  <Stack flex={{ flexGrow: 1 }}>
                    <FormImage
                      name={`bgi.${index}`}
                      value={form.bgi[index]}
                      label={`图片${index + 1}`}
                      onChange={handleFormChange}
                    />
                  </Stack>
                  <Stack flex={{ justifyContent: "center" }}>
                    <Button
                      onClick={() => {
                        const bgi = [...form.bgi];
                        bgi.splice(index, 1);
                        setForm({ ...form, bgi });
                      }}
                    >
                      删除
                    </Button>
                  </Stack>
                </Stack>
              ))}
              <Button
                onClick={() => {
                  setForm({ ...form, bgi: [...form.bgi, ""] });
                }}
              >
                添加
              </Button>
              {/* <Typography component={"div"} sx={{ m: 2, ml: 0 }} variant="caption">背景音乐</Typography>
              {form.bgm?.map((i: any, index: number) => (
                <Stack key={index} direction="row">
                  <Stack flex={{ flexGrow: 1 }}>
                    <FormImage
                      name={`bgm.${index}`}
                      value={form.bgm[index]}
                      label={`音频${index + 1}`}
                      onChange={handleFormChange}
                    />
                  </Stack>
                  <Stack flex={{ justifyContent: "center" }}>
                    <Button
                      onClick={() => {
                        const bgm = [...form.bgm];
                        bgm.splice(index, 1);
                        setForm({ ...form, bgm });
                      }}
                    >
                      删除
                    </Button>
                  </Stack>
                </Stack>
              ))}
              <Button
                onClick={() => {
                  setForm({ ...form, bgm: [...form.bgm, ""] });
                }}
              >
                添加
              </Button> */}
            </React.Fragment>}

          </DialogContent>
          <DialogActions>
            <Button
              disabled={!!data.find((i) => i.pid === form._id)}
              onClick={handleRemove}
            >
              删除
            </Button>
            <Stack flexGrow={1}></Stack>
            <Button onClick={() => setForm(undefined)}>取消</Button>
            <Button variant="contained" onClick={handleSave}>
              保存
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Container>
  ) : (
    <Loading variant="table" />
  );

  function row(pid: string = "", deep = 0) {
    const list = data?.filter((i) => i.pid === pid);
    return (
      <React.Fragment>
        {list?.map((i, index) => (
          <React.Fragment key={index}>
            <TableRow onClick={() => setForm({ ...i })} hover>
              <TableCell>
                <Stack sx={{ pl: `${deep * 2 + 1}rem` }}>
                  {i.title || "新栏目"}
                </Stack>
              </TableCell>
              <TableCell>{i.sort}</TableCell>
              <TableCell>
                <Button
                  disabled={!i._id || deep > 2}
                  onClick={(e) => {
                    handleAdd(
                      i._id!,
                      data!.filter((x) => x.pid === i._id).length
                    );
                    e.stopPropagation();
                  }}
                >
                  下级
                </Button>
              </TableCell>
              {/* <TableCell>
                <Button>设置</Button>
              </TableCell> */}
            </TableRow>
            {i._id && row(i._id, deep + 1)}
          </React.Fragment>
        ))}
        {data && deep === 0 && (
          <TableRow>
            <TableCell colSpan={3}>
              <Stack sx={{ pl: `${deep * 2}rem` }} direction="row">
                <Button onClick={() => handleAdd(pid, list?.length)}>
                  添加
                </Button>
              </Stack>
            </TableCell>
          </TableRow>
        )}
      </React.Fragment>
    );
  }
}
