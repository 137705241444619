import axios from "axios";
import { useLicense } from "../home/License";
import { snk, Snk } from "./app.service";

// api
export const APIHOST =
  window.location.host === 'localhost:3011'
    ? 'http://localhost:3010/'
    : '/'
//==============================={ axios 请求拦截 }
export const apiInit = () => {
  axios.interceptors.request.use(config => {
    if (config.method === 'post' && !(config.data && config.data.$noToken)) {
      const license = useLicense()
      config.baseURL = APIHOST;
      (config.headers as any)['appid'] = encodeURI(license.appId);
      (config.headers as any)['name'] = encodeURI(license.name);
      (config.headers as any)['sn'] = encodeURI(license.sn);
    }
    return config;
  });

  axios.interceptors.response.use(
    response => {
      if (response?.status >= 300) {
        const snkValue: Snk = { open: true, msg: response.statusText, severity: 'error' };
        snk.next(snkValue);
        return null;
      } else {
        return response;
      }
    },
    (error) => {
      switch (error?.response?.status) {
        case 403:
          snk.next({
            open: true,
            autoHideDuration: 10000,
            severity: 'error',
            msg: `App 授权无效`
          });
          if (window.location.pathname !== '/license') {
            // setTimeout(() => window.location.href = '/license', 3000)
          }
          break;
        default:
          snk.next({
            open: true,
            autoHideDuration: 10000,
            severity: 'error',
            msg: `${error?.name || '错误'}:${error?.message || '未知错误'}`
          });
      }
      return error?.response?.data
    }
  );
}

